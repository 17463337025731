<script>
import axios from "axios";

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
let user = JSON.parse(localStorage.getItem("user"));

/**
 * Register component
 */
export default {
  page: {
    title: "Sửa dịch vụ us - vn",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Sửa dịch vụ us - vn",
      items: [
        {
          text: "Trang Chủ",
          href: "/",
        },
        {
          text: "Dịch vụ",
          href: "/danh-sach-dich-vu",
        },
        {
          text: "Sửa dịch vụ us - vn",
          active: true,
        },
      ],

      service: {
        name: "Gửi hàng US-VN",
        type: 1,
        usvn: [
          {
            name: "Giá niêm yết",
            supplier: [
              {
                name: "Gửi đến sài gòn",
                price: 2.99,
                unit: "%",
                lb_min: 10,
                taxs: [
                    {
                    product_name: "Hàng thường",
                    product_type: 4,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng buôn",
                    product_type: 1,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng điện tử",
                    product_type: 2,
                    tax: 0,
                    tax_unit: "%",
                    disc: 0,
                    disc_unit: "%",
                  },
                  {
                    product_name: "Hàng đặc biệt ",
                    product_type: 3,
                    tax: 0,
                    tax_unit: "%",
                    disc: 0,
                    disc_unit: "%",
                  },
                ],
              },
              {
                name: "Gửi hàng đến các tỉnh",
                price: 0.99,
                unit: "$",
                lb_min: 10,
                provinces: [],
                taxs: [
                      {
                    product_name: "Hàng thường",
                    product_type: 4,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng buôn",
                    product_type: 1,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng điện tử",
                    product_type: 2,
                    tax: 0,
                    tax_unit: "%",
                    tax_opitions: ["%", "$", "cả 2"],
                    disc: 0,
                    disc_unit: "%",
                    disc_opitions: ["%", "$", "cả 2"],
                  },
                  {
                    product_name: "Hàng đặc biệt ",
                    product_type: 3,
                    tax: 0,
                    tax_unit: "%",
                    tax_opitions: ["%", "$", "cả 2"],
                    disc: 0,
                    disc_unit: "%",
                    disc_opitions: ["%", "$", "cả 2"],
                  },
                ],
              },
            ],
          },
          {
            name: "Giá đại lý",
            supplier: [
              {
                name: "Gửi đến sài gòn",
                price: null,
                unit: "%",
                lb_min: 10,
                taxs: [
                  {
                    product_name: "Hàng thường",
                    product_type: 4,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng buôn",
                    product_type: 1,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng điện tử",
                    product_type: 2,
                    tax: 0,
                    tax_unit: "%",
                    disc: 0,
                    disc_unit: "%",
                  },
                  {
                    product_name: "Hàng đặc biệt ",
                    product_type: 3,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                ],
              },
              {
                name: "Gửi hàng đến các tỉnh",
                price: null,
                unit: "$",
                lb_min: 10,
                provinces: [],
                taxs: [
                  {
                    product_name: "Hàng thường",
                    product_type: 4,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng buôn",
                    product_type: 1,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                  {
                    product_name: "Hàng điện tử",
                    product_type: 2,
                    tax: 0,
                    tax_unit: "%",
                    disc: 0,
                    disc_unit: "%",
                  },
                  {
                    product_name: "Hàng đặc biệt ",
                    product_type: 3,
                    tax: 0,
                    tax_unit: "$",
                    disc: 0,
                    disc_unit: "$",
                  },
                ],
              },
            ],
          },
        ],
      },
      submitted: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    // Try to register the service in with the email, name
    // and password they provided.

    tryToUpdateIn() {
      this.submitted = true;

      // stop here if form is invalid
      axios({
        url: process.env.VUE_APP_SERVER_URL + "/service/update",
        method: "post",
        headers: {
          authorization: user.token,
        },

        data: this.service,
      })
        .then(({ data }) => {
          this.$toasted.success("Sửa dịch vụ thành công");
        })
        .catch((error) => {
          this.$toasted.error("Sửa dịch vụ có lỗi");
        });
    },
  },

  created() {
    if (!this.$route.query.id) {
      this.$router.push("/");
    }
    let seft = this;
    let _id = this.$route.query.id;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/service/info",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          this.service = data;
        } else {
          this.$toasted.error("Không tìm thấy dịch vụ phù hợp");
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.$toasted.error("Không tìm thấy dịch vụ phù hợp");
        // this.$router.push("/");
      });
  },
};
</script>
<style>
.b-form-tag-content {
  border: 1px solid #e5e5e5;
  background: #f8f9fa;
  color: #000;
  border-radius: 2px;
  padding: 5px;
  text-transform: capitalize;
}

.b-form-tag-remove {
  border: 0px;
  margin-left: 2px;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="card overflow-hidden">
          <!-- <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Thêm tài khoản nhân viên mới</h5>
                  <p>Bạn cần nhập đầy đủ thông tin theo biểu mẫu.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div> -->

          <div class="card-body pt-0">
            <b-form class="my-2 p-2" @submit.prevent="tryToUpdateIn">
              <b-form-group
                class="mb-3"
                id="service-name"
                label="Tên dịch vụ"
                label-for="name"
              >
                <b-form-input
                  v-model="service.name"
                  type="text"
                  placeholder="Nhập tên"
                  aria-required=""
                ></b-form-input>
              </b-form-group>
              <div
                class="px-3"
                v-for="(usvn, index) in service.usvn"
                :key="usvn.name + usvn.type + index"
              >
                <!-- <div class="row">
                    <div class="col">
                      <b>{{usvn.name}}</b>
                    </div>
                </div> -->
                <div class="m-2" v-for="sup in usvn.supplier" :key="sup.name">
                  <b-row>
                    <b-col class="md-3">
                      <b-form-group
                        class="mb-3"
                        :label="usvn.name"
                        label-for="provide"
                      >
                        <b-form-input
                          v-model="sup.name"
                          debounce="1000"
                          type="text"
                          placeholder="Gửi hàng tới Sài Gòn"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group>
                        <span>Giá cho lb tối thiểu </span>
                        <div class="py-2">
                          <b-form-input
                            v-model="sup.price"
                            debounce="500"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Nhập dữ liệu"
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <span>Đơn vị </span>
                        <div class="py-2">
                          <b-form-select
                            class="form-control"
                            v-model="sup.unit"
                            size="md"
                            :options="['$', '%']"
                          ></b-form-select>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <span>LB tối thiểu </span>
                        <div class="py-2">
                          <b-form-input
                            v-model="sup.lb_min"
                            debounce="500"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Nhập dữ liệu"
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <span>Giảm giá </span>
                        <div class="py-2">
                          <b-form-input
                            v-model="sup.disc"
                            debounce="500"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Nhập dữ liệu"
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <span>Giảm giá Đơn vị </span>
                        <div class="py-2">
                          <b-form-select
                            class="form-control"
                            v-model="sup.disc_unit"
                            size="md"
                            :options="['$', '%']"
                          ></b-form-select>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <span>% dành cho nhân viên</span>
                        <div class="py-2">
                          <b-form-input
                            v-model="sup.salary"
                            debounce="500"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Nhập dữ liệu"
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div class="px-5 pb-2" v-if="sup.provinces">
                    <label for="tags-basic">Các tỉnh thành cùng giá</label>
                    <b-form-tags
                      separator=",;"
                      no-add-on-enter
                      input-id="tags-basic"
                      v-model="sup.provinces"
                      placeholder="add Province"
                    ></b-form-tags>
                  </div>

                  <h4 class="h5 px-5">Thuế... ?</h4>
                  <div
                    class="px-5"
                    v-for="tax in sup.taxs"
                    :key="tax.product_name + sup.name"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-3"
                          label="Loại sản phẩm"
                          label-for="service-usvn-tax"
                        >
                          <input
                            v-model="tax.product_name"
                            debounce="1000"
                            type="text"
                            placeholder="Tên loại sản phẩm"
                            class="form-control"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col v-if="tax.product_type != 3">
                        <b-form-group
                          class="mb-3"
                          label="Thuế loại sản phẩm"
                          label-for="service-usvn-tax"
                        >
                          <b-form-input
                            v-model="tax.tax"
                            debounce="1000"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Thuế loại sản phẩm"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <span>Đơn vị tính thuế </span>
                          <div class="py-2">
                            <b-form-select
                              class="form-control"
                              v-model="tax.tax_unit"
                              size="md"
                              :options="['$', '%']"
                            ></b-form-select>
                          </div>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group>
                          <span>% nhân viên </span>
                          <div class="py-2">
                            <b-form-input
                              v-model="tax.salary"
                              debounce="500"
                              type="number"
                              step="0.01"
                              min="0.00"
                              max="1000000.00"
                              placeholder="Nhập dữ liệu"
                            ></b-form-input>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <hr />
              </div>
              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Sửa dịch vụ</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>